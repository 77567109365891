import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from '@web3-react/injected-connector'
import React, { useEffect } from "react";
import ConnectWalletPage from "./ConnectWalletPage";
import HomePage from "./HomePage";
import { LastBlockProvider } from "./hooks/useLastBlock";

export default function Root() {
    const { active, activate } = useWeb3React();

    if (active) {
        return (
            <LastBlockProvider>
                <HomePage />
            </LastBlockProvider>
        );
    }

    return <ConnectWalletPage />
}