import { Card, Statistic } from "antd";
import { useEffect, useState } from "react";
import useBigNumbers from "../hooks/useBigNumbers";
import { useLastBlock } from "../hooks/useLastBlock";
import useRouletteContract from "../hooks/useRouletteContract";
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
    margin: {
        margin: 12,
    },
});

export default function LiquidityPool() {
    const { blockHash } = useLastBlock();
    const { formatWei } = useBigNumbers();
    
    const contract = useRouletteContract();
    const [pool, setPool] = useState<string | null>(null);
    const [staked, setStaked] = useState<string | null>(null);
    const [liquidity, setLiquidity] = useState<string | null>(null);

    useEffect(() => {
        const refresh = async () => {
            const poolValueStr = (await contract.methods.pool().call()).toString();
            const stakedValueStr = (await contract.methods.totalStaked().call()).toString();

            const poolValue = BigInt(poolValueStr);
            const stakedValue = BigInt(stakedValueStr);

            const liq: BigInt = poolValue - stakedValue;

            console.log(poolValue)

            setPool(poolValue.toString());
            setStaked(stakedValue.toString());
            setLiquidity(liq.toString());
        };

        refresh();
    }, [blockHash]);

    const formatEth = (n: string | null): string | null => {
        if (n == null) {
            return null;
        }

        return formatWei(n);
    };

    
    return (
        <Card title="Pool" className={css(styles.margin)}>
            <Statistic title="House Pool" value={formatEth(pool) ?? '???'}/>
            <Statistic title="Total Staked" value={formatEth(staked) ?? '???'}/>
            <Statistic title="House Margin" value={formatEth(liquidity) ?? '???'}/>
        </Card>
    );
}