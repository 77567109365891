import { useWeb3React } from "@web3-react/core"

export default function WalletHeader() {
    const { account } = useWeb3React();

    return (
        <span>
            { account ?? 'Connect Your Wallet' }
        </span>
    )
}