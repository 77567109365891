import { useWeb3React } from "@web3-react/core";


export default function useBigNumbers() {
    const decimals = BigInt(Math.pow(10, 18));
    const { library } = useWeb3React();

    return {
        weiToEth: (num: string): string => {
            return library.utils.fromWei(num);
        },
        ethToWei: (num: string | number): string => {
            return library.utils.toWei(num.toString(), 'ether');
        },
        formatWei: (num: string): string => {
            const eth = library.utils.fromWei(num);

            return eth + ' ETH';
        }
    }
}