import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import useBigNumbers from "./useBigNumbers";
import { useLastBlock } from "./useLastBlock";
import useRouletteContract from "./useRouletteContract";

export default function useViewerStake(): [string, string] {
    const [stake, setStake] = useState('???');
    const [harvestable, setHarvestable] = useState('???');
    const { account } = useWeb3React();
    // const { formatWei } = useBigNumbers();

    const { blockHash } = useLastBlock();
    const contract = useRouletteContract();

    useEffect(() => {
        const refreshStake = async () => {
            const myStake = await contract.methods.myStake().call({
                from: account,
            });
            const myHarvestableStr = await contract.methods.availableToHarvest().call({
                from: account,
            });


            setStake(myStake);
            setHarvestable(myHarvestableStr);
        };
        refreshStake();
    }, [ blockHash ]);


    return [stake, harvestable];
}