import { Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import InputNumber from "rc-input-number";
import { useState } from "react";
import Text from "antd/lib/typography/Text";
import { useWeb3React } from "@web3-react/core";
import useBigNumbers from "./useBigNumbers";
import useRouletteContract from "./useRouletteContract";
import { message } from 'antd';
import { useLastBlock } from "./useLastBlock";

export default function useStakeModal(): [() => void, React.ReactNode] {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [amountEth, setAmountEth] = useState(0.0);
    const { ethToWei } = useBigNumbers();
    const contract = useRouletteContract();
    const { account } = useWeb3React();
    const { updateBlock } = useLastBlock();

    const onConfirm = async () => {
        const stakeWei = ethToWei(amountEth);
        setLoading(true);        

        const block = await contract.methods.stake(stakeWei).send({
            from: account,
            value: stakeWei,
        });

        setLoading(false);
        setVisible(false);
        updateBlock(block.blockHash);
        message.success('Successfuly staked ' + amountEth + ' ETH!');
    };

    const modal = (
        <Modal
            title="Stake ETH"
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={onConfirm}
            confirmLoading={loading}>
            <div>
                <Space>
                    <InputNumber
                        placeholder={'0'}
                        defaultValue={0}
                        onChange={(v) => setAmountEth(v)}
                    />
                    ETH
                </Space>
                {/* <div>
                    <Text type="secondary">Your balance: {getBalance()}</Text>
                </div> */}
            </div>
        </Modal>
    );

    return [() => setVisible(true), modal];
}