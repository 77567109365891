import { useWeb3React } from "@web3-react/core";
import { Card, Col, Row } from "antd";
import React from "react";
import LiquidityPool from "./components/LiquidityPool";
import Roulette from "./components/Roulette";
import Staking from "./components/Staking";
import Unstaking from "./components/Unstaking";
import useRouletteContract from "./hooks/useRouletteContract";
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
    margin: {
        margin: 12,
    },
});

export default function HomePage() {
    return (
        <div>
            <Row>
                <Col span={24}>
                    <Roulette />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <LiquidityPool />
                </Col>
                <Col span={12}>
                    <Staking />           
                </Col>
            </Row>

        </div>
    );
}