import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { StyleSheet, css } from 'aphrodite';
import WalletHeader from "./WalletHeader";

const styles = StyleSheet.create({
    wrapper: {
        height: '100vh',
    },
    footer: {
        textAlign: 'center',
    },
    content: {
        margin: '24px 16px 0',
        overflowY: 'auto',
    },
    header: {        
        // padding: 0,
        padding: '0 40px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
    },
    filler: {
        flex: 1,
    },
    collapseButton: {
        padding: '0 24px',
        fontSize: '24px',
        cursor: 'pointer',
        transition: 'color 0.3s',
        // color: '#fff',
        ':hover': {
            color: '#1890ff',
        },
    },
});

interface Props {
    children: any,
}

export default function MainLayout({children}: Props) {        
    return (
        <Layout className={css(styles.wrapper)}>
            <Layout>
                <Header className={css(styles.header)}>
                    <span>DeMonteCarlo</span>
                    <WalletHeader />
                </Header>
                <Content className={css(styles.content)}>
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}