import React from 'react';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3';
import Root from './app/Root';
import MainLayout from './app/layout/MainLayout';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

function getLibrary(provider: any) {
  return new Web3(provider) // this will vary according to whether you use e.g. ethers or web3.js
}


function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MainLayout>
        <Root/>
      </MainLayout>
    </Web3ReactProvider>
  );
}

export default App;
