import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useEffect } from "react";

export default function ConnectWalletPage() {
    const metamask = useWeb3React();
    const injected = new InjectedConnector({ supportedChainIds: [3] });

    useEffect(() => {
        const checkLaunch = async () => {
            if (await injected.isAuthorized()) {
                metamask.activate(injected);
            }
        };

        checkLaunch();
    }, []);

    return (
        <div>
            Hello! Connect your wallet please.
            <button onClick={() => metamask.activate(injected)}>
                Connect metamask
            </button>
            {metamask.error && <div>
                {metamask.error.toString()}
            </div>}
        </div>
    );
    
}