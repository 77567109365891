import { useWeb3React } from "@web3-react/core";
import { Button, Card, Space, Statistic } from "antd";
import React, { useState } from "react";
import useBigNumbers from "../hooks/useBigNumbers";
import { useLastBlock } from "../hooks/useLastBlock";
import useRouletteContract from "../hooks/useRouletteContract";
import { StyleSheet, css } from 'aphrodite';
import useViewerStake from "../hooks/useViewerStake";
import useStakeModal from "../hooks/useStakeModal";
import useUnstakeModal from "../hooks/useUnstakeModal";

const styles = StyleSheet.create({
    margin: {
        margin: 12,
    },
    harvest: {
        display: 'flex',
        alignItems: 'center',        
    }
});

export default function Staking() {
    const [ staked, harvestable ] = useViewerStake();
    const [showStakeModal, stakeModal] = useStakeModal();
    const [showUnstakeModal, unstakeModal] = useUnstakeModal();
    const { account } = useWeb3React();
    const { formatWei } = useBigNumbers();

    const contract = useRouletteContract();
    const { updateBlock } = useLastBlock();

    const onHarvest = async () => {
        setHarvestLoading(true);

        const block = await contract.methods.harvest().send({
            from: account,
        });

        updateBlock(block.blockHash);
        setHarvestLoading(false);
    };

    const [harvestLoading, setHarvestLoading] = useState(false);

    return (
        <Card title="Stake" className={css(styles.margin)}  actions={[
            <Button type="link" onClick={showStakeModal}>Stake</Button>,
            <Button type="text" onClick={showUnstakeModal}>Unstake</Button>,
        ]}>
            <Statistic title="Your Stake" value={formatWei(staked === '???' ? '0' : staked)} />
            <div className={css(styles.harvest)}>
            <Statistic title="Harvestable" value={formatWei(harvestable === '???' ? '0' : harvestable)} />
            
            { harvestable !== '???' && harvestable !== '0' &&
            <div className={css(styles.margin)}>
                <Button type="dashed" onClick={onHarvest} loading={harvestLoading}>Harvest</Button>
            </div>
            }
            
            </div>
            {stakeModal}
            {unstakeModal}
        </Card>
    );
}