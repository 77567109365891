import { useWeb3React } from "@web3-react/core";
import { Button, Card, Radio, Space, Spin } from "antd";
import { useState } from "react";
import { useLastBlock } from "../hooks/useLastBlock";
import useRouletteContract from "../hooks/useRouletteContract";
import { StyleSheet, css } from 'aphrodite';
import { Wheel } from 'react-custom-roulette';
import useBigNumbers from "../hooks/useBigNumbers";
import { message } from 'antd';

const styles = StyleSheet.create({
    margin: {
        margin: 12,
    },
    verticalSpace: {
        margin: '20px 0',
    }
});

export default function Roulette() {
    const { account } = useWeb3React();
    const { updateBlock } = useLastBlock();

    const { ethToWei, weiToEth, formatWei } = useBigNumbers();

    const contract = useRouletteContract();
    const [betAmount, setBetAmount] = useState(0.0);

    const [loading, setLoading] = useState(false);

    const [amountWont, setAmountWon] = useState(null);
    const [winningNumber, setWinningNumber] = useState(1);
    const [spin, setSpin] = useState(false);

    const [singleBetOption, setSingleBetOption] = useState(0);

    const onPlay = async () => {
        // setSpin(true);
        const betWei = ethToWei(betAmount);

        setLoading(true);

        const block = await contract.methods.playRoulette(betWei, singleBetOption).send({
            from: account,
            value: betWei,
        });

        updateBlock(block.blockHash);

        const result = block.events.RouletteResult;
        const { amountWon, rouletteNumber } = result.returnValues;

        setLoading(false);

        const prizeNumber = parseInt(rouletteNumber);
        setWinningNumber(prizeNumber);
        setAmountWon(amountWon);
        setSpin(true);


        console.log("Amount won: " + amountWon);
        console.log("Roulette Number: " + prizeNumber);
    };

    const onStopSpin = () => {
        setSpin(false);


        if (amountWont === '0') {
            message.info('Better luck next time!');
        } else {
            message.success('You have won ' + formatWei(amountWont ?? '0') + '!');
        }

    };

    const data = [
        { option: '0', style: { backgroundColor: 'green', textColor: 'white' } },
    ];

    const isRed = (i: number) => {
        if (i <= 10 || (i >= 19 && i <= 28)) {
            return i % 2 == 1;
        } else {
            return i % 2 == 0;
        }
    };

    for (let i = 1; i <= 36; i++) {
        data.push({
            option: i.toString(),
            style: {
                backgroundColor: !isRed(i) ? 'rgb(30, 30, 30)' : 'rgb(160, 15, 20)',
                textColor: 'white',
            },
        });
    }

    const betOptions = Array.from(Array(36).keys()).map((i) => {
        return <Radio.Button key={'bet-option-' + i} value={i}>{i.toString()}</Radio.Button>
    });

    console.log('winning number');
    console.log(winningNumber);

    return (
        <Card title="Roulette" className={css(styles.margin)}>
            <Space>
                <Wheel
                    mustStartSpinning={spin}
                    prizeNumber={winningNumber}
                    data={data}
                    innerRadius={50}
                    innerBorderWidth={20}
                    perpendicularText={true}
                    textDistance={85}
                    fontSize={15}
                    outerBorderWidth={10}
                    outerBorderColor={'rgb(35,35,35)'}
                    innerBorderColor='rgb(35,35,35)'

                    radiusLineWidth={2}
                    radiusLineColor={'gold'}

                    onStopSpinning={onStopSpin}
                />
                <div>
                    <Spin spinning={loading}>

                        <div>Bet Amount:</div>
                        <div>
                            <input onChange={(e) => setBetAmount(parseFloat(e.target.value))} placeholder="ETH" />
                        </div>                        
                        <div>Bet On:</div>
                        <Radio.Group value={singleBetOption} onChange={(v) => setSingleBetOption(v.target.value)}>                        
                            {betOptions}
                            <Radio.Button value={37} key='bet-red'>Red</Radio.Button>
                            <Radio.Button value={38} key='bet-red'>Black</Radio.Button>
                            <Radio.Button value={39} key='bet-red'>Odd</Radio.Button>
                            <Radio.Button value={40} key='bet-red'>Even</Radio.Button>
                            <Radio.Button value={41} key='bet-red'>1..18</Radio.Button>
                            <Radio.Button value={42} key='bet-red'>19..36</Radio.Button>
                        </Radio.Group>
                        <div className={css(styles.verticalSpace)}>
                            <Button onClick={onPlay} type="primary" size="large">
                                Play!
                            </Button>
                        </div>
                    </Spin>
                </div>
            </Space>

        </Card>
    );
}