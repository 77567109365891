import React, { useContext, useMemo, useState } from "react";

interface LastBlockContext {
    blockHash: string,
    updateBlock: (hash: string) => void,
}

export const LastBlockContext = React.createContext<LastBlockContext>({
    blockHash: '',
    updateBlock: () => {},
});

export function useLastBlock(): LastBlockContext {
    return useContext<LastBlockContext>(LastBlockContext);
}

interface Props {
    children: any,
}

export function LastBlockProvider({children}: Props) {
    const [lastBlock, setLastBlock] = useState('');

    const ctxValue = useMemo(() => ({
        blockHash: lastBlock,
        updateBlock: setLastBlock,
    }), [lastBlock, setLastBlock]);

    return (
        <LastBlockContext.Provider value={ctxValue}>
            {children}
        </LastBlockContext.Provider>
    )
}